.main{
    position: fixed;
    bottom: 120px;
    right: 15px;
    font-size: 16px;
    z-index: 100000;


    border-radius: 100%;
    width: 77px;
    height: 77px;

    box-shadow: 0 5px 8px 0 rgba(0, 142, 40, 0.05);
    background: var(--main-background-navigate-button); 

    display: flex;
    align-items: center;
    justify-content: center;

    &-sub{
        width: 60px;
        height: 60px;
        border-radius: 100%;
        box-sizing: border-box;

        box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.15);
        background: var(--sub-background-navigate-button);

        display: flex;
        align-items: center;
        justify-content: center;    

        &-coin{
            padding: 10px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;

            display: flex;
            align-items: center;
            justify-content: center;  

          
            >div{
                width: 100%;
                height: 100%;

                background-image: url("../../../Resources/images/clicker-background-default.svg");
                background-position: center;
                background-size: 102%;

                display: flex;
                align-items: center;
                justify-content: center;  

                &:after {
                    content: "";
                    width: 17.875px;
                    height: 34.125px;
                    display: block;
                    background-image: url("../../../Resources/images/human-free.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }
}