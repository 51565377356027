.navigationBlock {
    padding-top: 30px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: 16px;

    .navigationBlock_navItem {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        color: var(--secondary-font-color);

        text-decoration: none;
        text-wrap: nowrap;

        padding: 6px 12px;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: var(--action-component-active-background-light-color);
            height: 1px;
            opacity: 0;
            transition: opacity .3s ease;
        }

        position: relative;

        &.active {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 14px;
            background: var(--action-component-active-background-light-color);
            background-clip: text;
            transition: all .3s ease;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;



            &:hover::after,
            &.active::after {
                opacity: 1;
            }
        }

    }

    :not(:last-child) {
        margin-right: 8px;
    }


}