.container {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    will-change: transform;
    z-index: 100000;
    position: relative;
}

.coin-game-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

@keyframes shake {
    0% {
        transform: translateX(0) scale(1);
    }

    25% {
        transform: translateX(3px) scale(1.02);
    }

    50% {
        transform: translateX(-3px) scale(1.02);
    }

    75% {
        transform: translateX(3px) scale(1.02);
    }

    100% {
        transform: translateX(0) scale(1);
    }
}

.shake {
    animation: shake 0.3s ease infinite;
}


.coin_count-container {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
}

.coin_count_container-value {
    display: block;
    font-size: 32px;
    z-index: 10;

    color: #fff;

    font-family: "Pixel";

    display: flex;
    align-items: center;

    transition: transform 0.1s ease-in-out;
    transition: color 0.5s ease, transform 0.5s ease;
}

.coin_count_container-value.animate {
    transform: scale(1.1);
}

.coin_count_container-value.red {
    color: red;
    transform: scale(1.2);
}

.coin_count_container-value img {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.game-time {
    margin-left: 20px;
    font-size: 20px;
    color: #fff;
    font-family: "Pixel";
}

.coint_count_container-value>img {
    display: block;
    width: 30px;
    height: 30px;

    margin-right: 4px;
}

.falling-coins-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.coin {
    position: absolute;
    z-index: 101;
    top: 0;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    animation: fall linear forwards, pulse 0.3s infinite ease-in-out;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    transform: scale(1);
}

.coin.paused {
    animation-play-state: paused;
}

.coin-glow {
    transition: transform 0.2s ease;
    transform: scale(1.2);
}

.damage {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: red;
    animation: fadeOut 2s ease-in-out;

    font-family: "Pixel";
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translate(-50%, -20px);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -40px);
    }
}



.coin img {
    border-radius: 50%;
    pointer-events: none;
}

.coin-small img {
    width: 10vw;
}

.coin-medium img {
    width: 12vw;
}

.coin-normal img {
    width: 14vw;
}

.coin-large img {
    width: 16vw;
}

@keyframes fall {
    0% {
        top: -50px;
    }

    100% {
        top: 100vh;
    }
}

.play-button-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
}

.play-button {
    padding: 10px 20px;
    font-size: 24px;
    cursor: pointer;
    background-color: #BBF245;
    font-family: "Pixel";
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;

    user-select: none;
}

.play-button:disabled {
    background-color: gray;
    cursor: not-allowed;
    opacity: 0.6;
}

.play-time {
    margin-top: 5px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    font-family: "Pixel";
    color: gray;
    font-size: 12px;
}


.modal-backdrop_container-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 22, 21, 0.57);
    backdrop-filter: blur(5px);

    z-index: 100000;
}

.modal-backdrop_container-content {
    border-top: 2px solid var(--main-green-color);
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 190px;
    box-sizing: border-box;

    background-color: #101615;
    position: fixed;
    bottom: 0;
    left: 0;

    z-index: 100001;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
    text-align: center;

    color: white
}

.modal-backdrop_container-content>h2 {
    margin-bottom: 15px;
}

.action-buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.action-buttons_play-again-container {
    position: relative;
}

.action-buttons_play-again-button {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: var(--main-green-color);
    font-family: "Pixel";
    color: white;
    border: none;
    user-select: none;

    margin-right: 10px;
}

.action-buttons_play-continue-button {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: var(--main-green-color);
    font-family: "Pixel";
    color: white;
    border: none;
    user-select: none;

    margin-top: 20px;
}

.action-buttons_play-again-button:disabled {
    background-color: gray;
    cursor: not-allowed;
    opacity: 0.6;
}

.action-buttons_play-again-time {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    font-family: "Pixel";
    color: gray;
    font-size: 12px;
}

.action-exit-button {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: gray;
    font-family: "Pixel";
    color: white;
    border: none;
    user-select: none;
}

.frozen-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
    box-shadow: inset 0 0 15px 5px rgba(173, 216, 230, 0.5),
        inset 0 0 30px 10px rgba(0, 191, 255, 0.3),
        inset 0 0 45px 15px rgba(0, 0, 255, 0.2);
    background: rgba(173, 216, 230, 0.1);
    animation: frozenEffectAnimation 4s ease-out;
}

@keyframes frozenEffectAnimation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bomb-effect {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 80%);
    pointer-events: none;
    animation: bombEffectAnimation 1s ease-in-out;
    opacity: 0;
}

@keyframes bombEffectAnimation {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        transform: scale(3);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.bonus-text {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #f7da36;
    animation: fadeOutUp 0.5s ease forwards;
    font-family: "Pixel";
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}