:root {
    --font-family: "Inter";
    --gray: #6c7c94;
    --font13: "Prosto One";
    --black-color: #17273e;
    --white: #f3f3f6;
    --second-family: "Inter", sans-serif;
    --white-color: #fefefe;
    --light-gray: #dee4f1;
    --green-: #a7f16b;
    --green: #b5e627;
}

[data-theme="light"] {
    --background-color: #151515;
    --background-image: url('./Resources/Predict/images/fon.png');
    --bottom-menu-background-color: linear-gradient(90deg, #515151 0%, #3e3e3e 100%);

    --action-component-background-color: #313131;
    --action-component-secondary-background-color: rgba(255, 255, 255, 0.2);
    --action-component-background-light-color: #313131;
    --action-component-active-background-light-color: var(--green-);

    --action-component-color: #738ea2;

    --badge-background: rgba(233, 234, 248, 0.07);

    --light-badge-background: #f5f6fa;

    /* Font  */

    --main-font-color: var(--white-color);
    --main-colorize-font-color: var(--green-);
    --reverse-main-font-color: #171717;
    --secondary-font-color: rgba(255, 255, 255, 0.56);
    --light-secondary-font-color: #b7b7b7;
    --sub-title-font-color: #4988f0;
    --card-secondary-bold-color: #212121;
    --success-button-font-color: #212121;
    --blue-linear-gradient-font-background: linear-gradient(180deg,
            #5594fd 0%,
            #3960f5 100%);
    --leadboard-title-font-color: #191919;


    --leader-board-font-color: linear-gradient(180deg, #5594fd 0%, #3960f5 100%);
    --leader-board-first-font-color: var(--white-color);

    --predict-add-photo-font-color: #676767;

    /* Components  */

    --card-background-color: #3b3b3b;
    --card-dark-background-color: #3b3b3b;
    --card-secondary-background-color: linear-gradient(90deg, #2f2f2f 0%, #313030 100%);
    --input-background-color: #585858; 

    --card-badge-background: var(--green-);
    --out-line-dark-card-background-color: var(--card-background-color);
    --blue-card-background-color: #dde6fa;

    --out-line-dark-card-border: none;

    --prediction-entered-up-button-background-color: rgba(167, 241, 107, 0.76);
    --prediction-card-backgroun-color: #585858;

    --comment-block-background: #515151;
    --comments-block-background: linear-gradient(176deg, #3e3e3e 0%, #313131 100%);
    --comment-block-color: var(--green);

    --button-background: #4a4a4a;

    --button-secondary-background: #515151;

    --divider-background: rgba(255, 255, 255, 0.05);

    --dark-divider-background: var(--light-gray);

    --family-member-count-badge-background: #A7F16B;
    --family-member-count-badge-font-color: #101011;

    --progress-bar-background: var(--green-);

    --money-counter-background: #585858;

    --toggle-switch-background: #3a3a3a;
    --active-toggle-switch-background: #7f7f7f;

    --price-color-selection-background: var(--green-);
    --price-color-selection-background-red: #E84266;

    --success-button-background: var(--green-);

    --input-font-color: rgba(183, 183, 183, 0.79);
    --input-light-font-color: #fff;

    --confirm-button-background-color: var(--green-);

    --mini-card-background-color: rgba(88, 88, 88, 0.95);

    --family-leadboard-card-backgound: var(--green-);
    --family-leadboard-card-second-backgound: rgba(59, 59, 59, 0.69);
    --family-leadboard-place-badge-background-color: rgba(255, 255, 255, 0.22);

    --level-badge-backgroun-color: #585858;

    --text-input-backgroun-color: #2f2f2f;

    --divider_background-color: rgba(58, 58, 58, 0.44);

    --active-status-circle-background-color: var(--green);
    --active-status-badge-background-color: #585858;

    --loader-background: radial-gradient(farthest-side, #bbf245 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, #bbf245);
    --loader-border: #A7F16B;

    --add-predict-button-background-color: #484848;
    --notification-card-background-color: rgba(58, 58, 58, 0.57);

    --predict-leadboard-card-background-color: #3c3c3c;
    --predict-leadboard-current-card-background-color: #383b30;
    --predict-leadboard-card-border: var(--green-);

    --gilling-glass-timer-color: #25b35e; 

    --sub-background-navigate-button: var(--green-);
    --main-background-navigate-button: rgba(167, 241, 107, 0.33);

    --bottom-menu-centred-button-background: #A7F16B;
}

[data-theme="dark"] {
    --background-color: #0b0123;
    --background-image: url('./Resources/Predict/images/fon_dark.png');
    --bottom-menu-background-color: rgba(35, 26, 66, 0.69);

    --action-component-background-color: rgba(35, 26, 66, 0.81);
    --action-component-secondary-background-color: #463a71;
    --action-component-background-light-color: #180f31;
    --action-component-active-background-light-color: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);

    --action-component-color: #cacacf;

    --badge-background: rgba(233, 234, 248, 0.07);

    --light-badge-background: #232232;

    /* Font  */

    --main-font-color: var(--white-color);
    --main-colorize-font-color: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);
    --reverse-main-font-color: var(--white-color);
    --secondary-font-color: rgba(180, 180, 227, 0.65);
    --light-secondary-font-color: #7d7aa5;
    --card-secondary-bold-color: #f6ecef;
    --success-button-font-color: #25b35e;
    --sub-title-font-color: #fff;
    --blue-linear-gradient-font-background: linear-gradient(90deg,
            #61adde 0%,
            #298eef 100%);
    --leadboard-title-font-color: #fff;

    --leader-board-font-color: #101615;
    --leader-board-first-font-color: #101615;

    --predict-add-photo-font-color: #4c4266;


    /* Components  */

    --card-background-color: #231a42;
    --card-dark-background-color: #1C133A;
    --card-secondary-background-color: #251b43;
    --card-badge-background: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);
    --input-background-color: #251B43; 

    --out-line-dark-card-background-color: transparent;
    --blue-card-background-color: #151422;
    --glowing-card-background: radial-gradient(50% 50% at 50% 50%,
            rgb(105, 102, 141) 0%,
            rgb(61, 58, 86) 100%);

    --out-line-dark-card-border: 1px solid #4373f7;

    --prediction-entered-up-button-background-color: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);
    --prediction-card-backgroun-color: #312753;

    --comment-block-background: #231a42;
    --comments-block-background: #180f31;
    --comment-block-color: #9584e9;

    --button-background: #3c3161;
    --button-secondary-background: #231a42;

    --divider-background: rgba(255, 255, 255, 0.05);
    --dark-divider-background: rgba(65, 65, 77, 0.67);

    --family-member-count-badge-background: #7766DF;
    --family-member-count-badge-font-color: #fff;

    --progress-bar-background: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);

    --money-counter-background: rgba(49, 39, 83, 0.53);

    --toggle-switch-background: rgba(238, 237, 255, 0.17);
    --active-toggle-switch-background: rgba(238, 237, 255, 0.45);

    --price-color-selection-background: #25b35e;
    --price-color-selection-background-red: #FE3D67;

    --success-button-background: rgba(40, 175, 94, 0.16);

    --input-font-color: rgba(125, 122, 165, 0.79);
    --input-light-font-color: #7d7aa5;

    --confirm-button-background-color: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);

    --mini-card-background-color: rgba(49, 39, 83, 0.95);

    --family-leadboard-card-backgound: linear-gradient(106deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);
    --family-leadboard-card-second-backgound: #1b1237;
    --family-leadboard-place-badge-background-color: rgba(249, 252, 255, 0.22);

    --level-badge-backgroun-color: #382f5a;

    --text-input-backgroun-color: #251b43;

    --divider_background-color: linear-gradient(90deg, #312753 2%, #42356d 54.5%, #312753 100%);

    --active-status-circle-background-color: #49f0b4;
    --active-status-badge-background-color: #382f5a;

    --loader-background: radial-gradient(farthest-side, #6e65dc 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, #bb69f5);
    --loader-border: #bb69f5;

    --add-predict-button-background-color: #2b224d;
    --notification-card-background-color: rgba(30, 21, 60, 0.89);

    --predict-leadboard-card-background-color: linear-gradient(98deg, #1d163d 0%, #170e33 100%);
    --predict-leadboard-current-card-background-color: #3f2647;
    --predict-leadboard-card-border: #BB69F5;

    --gilling-glass-timer-color: #bb69f5; 

    --sub-background-navigate-button: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);
    --main-background-navigate-button: rgba(130, 102, 227, 0.49);

    --bottom-menu-centred-button-background: linear-gradient(118deg, #6e65dc 0%, #bb69f5 51%, #b241e5 100%);

}

@font-face {
    font-family: "Urbanist";
    src: url("./Resources/Predict/fonts/Urbanist.ttf") format("truetype");
}

@font-face {
    font-family: "Prosto One";
    src: url("./Resources/Predict/fonts/ProstoOne.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    src: url("./Resources/Predict/fonts/Inter.ttf") format("truetype");
}