.divider {
    width: 1px;
    height: 20px;

    background: var(--divider-background);
}

.pixieFamiliesContainer {
    padding: 0 16px;

    .pixieFamiliesContainer_welcomeBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pixieFamiliesContainer_welcomeBox_title {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: var(--main-font-color);

            margin-bottom: 8px;
        }

        .pixieFamiliesContainer_welcomeBox_subTitle {
            font-family: var(--font-family);
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            color: var(--main-font-color);
        }
    }

    .pixieFamiliesContainer_content {
        margin-top: 16px;
    }

    .familyBlock {
        position: relative;

        .familyBlock_statistics {
            display: flex;
            justify-content: space-around;
            align-items: center;

            margin-top: 20px;

            border-radius: 68px;
            padding: 4px 16px;
            height: 47px;
            background: var(--card-background-color);
            box-sizing: border-box;

            z-index: 100;

            .familyBlock_statisticsItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .familyBlock_statisticsItemTitle {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 132%;
                    color: var(--light-secondary-font-color);

                    margin-bottom: 2px;
                }

                .familyBlock_statisticsItemSubTitle {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 132%;
                    color: var(--main-font-color);
                }
            }
        }

        .familyBlock_wallpaper {
            position: fixed;
            right: -30px;
            bottom: 70px;
            margin-top: 150px;
            display: flex;
            justify-content: end;

            z-index: 50;

            img {

                width: 260px;
                height: 296px;

            }
        }
    }

    .leaderboardBlock {
        .leaderboardBlock_topFamily {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;

            >:not(:last-child) {
                margin-right: 14px;
            }

            .leaderboardBlock_topFamily_second {
                box-sizing: border-box;
                border-radius: 12px;
                padding: 14px 24px;
                width: 99px;
                height: 122px;
                background: var(--family-leadboard-card-second-backgound);

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                margin-bottom: 8px;


                .leaderboardBlock_topFamily_second_place {
                    position: relative;
                    display: inline-block;

                    border-radius: 5px;
                    width: 17px;
                    height: 17px;

                    background: var(--family-leadboard-place-badge-background-color);
                    transform: rotate(-45deg);

                    margin-bottom: 8px;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        transform: translate(-50%, -50%) rotate(45deg);
                        background-color: #f1f1f1;

                        font-family: var(--second-family);
                        font-weight: 600;
                        font-size: 8px;
                        text-align: center;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                .leaderboardBlock_topFamily_second_familyName {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 9px;
                    letter-spacing: -0.05em;
                    color: var(--main-font-color);

                    margin-top: 8px;
                }

                .leaderboardBlock_topFamily_second_persent {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 15px;
                    background: var(--main-colorize-font-color);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    margin-top: 8px;
                }

                .leaderboardBlock_topFamily_second_amount {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--main-font-color);

                    margin-top: 8px;
                }
            }

            .leaderboardBlock_topFamily_first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                box-sizing: border-box;
                border-radius: 12px;
                padding: 14px 24px;
                width: 117px;
                height: 125px;
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.19);
                background: var(--family-leadboard-card-backgound);

                margin-bottom: 8px;

                .leaderboardBlock_topFamily_first_place {
                    position: relative;
                    display: inline-block;

                    border-radius: 5px;
                    width: 17px;
                    height: 17px;

                    background: rgba(244, 251, 255, 0.47);
                    transform: rotate(-45deg);

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);

                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 8px;
                        text-align: center;
                        color: var(--leadboard-title-font-color);
                    }
                }

                .leaderboardBlock_topFamily_first_familyName {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 12px;
                    letter-spacing: -0.03em;
                    color: var(--leadboard-title-font-color);

                    margin-top: 8px;
                }

                .leaderboardBlock_topFamily_first_persent {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 15px;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-color: var(--leadboard-title-font-color);

                    margin-top: 8px;
                }

                .leaderboardBlock_topFamily_first_amount {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--leadboard-title-font-color);
                    margin-top: 8px;
                }
            }
        }

        .leaderboardBlock_otherFamily {
            margin-top: 8px;

            >:not(:last-child) {
                margin-bottom: 8px;
            }

            .leaderboardBlock_otherFamily_familyCard {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background-color: var(--card-background-color);

                .leaderboardBlock_otherFamily_leftBlock {
                    display: flex;
                    align-items: center;

                    .leaderboardBlock_otherFamily_leftBlock_place {
                        position: relative;
                        display: inline-block;
                        margin-right: 11px;

                        border-radius: 5px;
                        width: 17px;
                        height: 17px;

                        background: var(--family-leadboard-place-badge-background-color);
                        transform: rotate(-45deg);

                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;

                            transform: translate(-50%, -50%) rotate(45deg);
                            background-color: #f1f1f1;

                            font-family: var(--second-family);
                            font-weight: 600;
                            font-size: 8px;
                            text-align: center;
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                    .leaderboardBlock_otherFamily_leftBlock_info {
                        .leaderboardBlock_otherFamily_leftBlock_info_title {
                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 13px;
                            letter-spacing: -0.03em;
                            color: var(--main-font-color);

                            margin-bottom: 8px;
                        }

                        .leaderboardBlock_otherFamily_leftBlock_info_subTitle {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 120%;
                            letter-spacing: -0.03em;
                            color: var(--main-font-color);

                            opacity: 0.7;
                        }
                    }

                }

                .leaderboardBlock_otherFamily_rightBlock {
                    >div {
                        font-family: var(--font-family);
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        letter-spacing: -0.03em;
                        color: var(--main-font-color);

                        margin-bottom: 6px;
                    }
                }

            }

        }
    }

    .contestsBlock {
        >:not(:last-child) {
            margin-bottom: 16px;
        }

        .contestsBlock_contestCard {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .contestsBlock_contestCard_left {
                display: flex;
                align-items: center;

                img {
                    width: 38px;
                    height: 38px;

                    margin-right: 11px;
                }

                .contestsBlock_contestCard_left_info {
                    .contestsBlock_contestCard_left_info_title {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 13px;
                        letter-spacing: -0.03em;
                        color: var(--main-font-color);

                        margin-bottom: 8px;
                    }

                    .contestsBlock_contestCard_left_info_subTitle {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 120%;
                        letter-spacing: -0.03em;
                        color: var(--main-font-color);
                        opacity: 0.7;
                    }
                }
            }

            .contestsBlock_contestCard_status {
                border-radius: 20px;
                padding: 7.5px 26px;
                box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
                background: var(--action-component-active-background-light-color);

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 14px;
                line-height: 132%;
                text-align: center;
                color: #fff;
            }
        }
    }
}