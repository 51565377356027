.swap-container {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: var(--main-font-color);

            &-back {
                display: flex;
                align-items: center;
                margin-right: 10px;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &-blocks {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-block {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 10px;
            background: var(--mini-card-background-color);
            border: 0.5px solid #ffff;

            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: var(--main-font-color);

                > :nth-child(1) {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                }

                > :nth-child(2) {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                }
            }

            &-content {
                color: var(--main-font-color);

                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                :nth-child(1) {
                    max-width: 100px;
                    input {
                        border-bottom: 1px solid #ffff;

                        outline: none;
                        background: none;
                        border: none;
                        -moz-appearance: textfield;

                        /* Убирает стрелки в Chrome */
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        font-family: var(--font-family);
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 132%;
                        text-align: left;
                        color: var(--white-color);
                    }
                }

                :nth-child(2) {
                    display: flex;
                    align-items: center;

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;

                    img {
                        width: 16px;
                        height: 16px;

                        margin-right: 4px;
                    }
                }
            }
        }

        &-divider {
            width: 50px;
            height: 50px;

            margin: 30px 0;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    &-swap-button {
        width: 100%;
        box-sizing: border-box;
        border-radius: 15px;
        margin-top: 40px;

        padding: 10px 20px;
        text-align: center;
        cursor: pointer;

        color: var(--main-font-color);
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--action-component-active-background-light-color);
    }
}

.swap-notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200000;

    .swap-notification-container {
        background: var(--mini-card-background-color);
        border-radius: 12px;
        padding: 20px;
        width: 80%;
        max-width: 400px;
        text-align: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        color: var(--main-font-color);

        .swap-notification-header {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .swap-notification-message {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .swap-notification-button {
            box-sizing: border-box;
            border-radius: 15px;
            padding: 10px 20px;
            text-align: center;
            cursor: pointer;

            color: var(--main-font-color);
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;

            background: var(--action-component-active-background-light-color);
        }
    }
}
