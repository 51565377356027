.mainPageContainer{
    padding: 16px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }

        .header_rightBlock {
            display: flex;
            align-items: center;

            >:not(:last-child) {
                margin-right: 8px;
            }
        }

        .balance {
            cursor: pointer;
        }

        .header_rightBlock_element {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_rightBlock_element_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff
            }

            .header_rightBlock_element_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }
        }

        margin-bottom: 16px;
    }

    &-card-list{
        >:not(:last-child){
            margin-bottom: 16px;
        }
    }

}