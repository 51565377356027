input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* Убираем стрелочки в Firefox */
}

.input {
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;

    outline: none;
    transition: border-color 0.3s;
    border: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
    line-height: 134%;

    &.error {
        outline: 0.5px solid #e84266;
    }
}

.dark {
    background: var(--text-input-backgroun-color);
    color: var(--input-font-color);

    &::placeholder {
        color: var(--input-font-color);
    }
}

.light {
    background: var(--text-input-backgroun-color);
    color: var(--input-light-font-color);

    &::placeholder {
        color: var(--light-secondary-font-color);
    }
}