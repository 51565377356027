.miniLoader {
    width: 20px;
    height: 20px;

}

.miniLoader.dark {
    border: 5px solid var(--loader-border);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.miniLoader.light {
    border: 5px solid white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // background-color: var(--background-color);
    // background-image: var(--background-image);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;


    .loader_text {
        color: #f1f1f1;
        margin-top: 20px;
        width: 77px;
        display: block;
        text-transform: uppercase;
    }

    .loader {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: var(--loader-background);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
        animation: l13 1s infinite linear;
    }
}

@keyframes l13 {
    100% {
        transform: rotate(1turn)
    }
}