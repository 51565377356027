.marketplaceContainer {
    padding: 0 16px;

    .marketplaceContainer_welcomeBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .marketplaceContainer_welcomeBox_title {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: var(--main-font-color);

            margin-bottom: 8px;
        }

        .marketplaceContainer_welcomeBox_subTitle {
            font-family: var(--font-family);
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            color: var(--main-font-color);
        }
    }

    .marketplaceContainer_content {
        margin-top: 16px;
    }

    .marketplaceNftsBlock {
        margin-top: 16px;

        display: grid;
        grid-template-columns: repeat(auto-fill, 164px);
        gap: 16px;
        justify-content: center;

        .marketplaceNftsBlock_nftCard {
            border-radius: 18px;
            box-sizing: border-box;
            padding: 8px;
            width: 164px;
            height: 202px;

            background: var(--card-background-color);

            >img {
                border-radius: 14px;
                width: 148px;
                height: 113px;
            }

            .marketplaceNftsBlock_nftCard_title {
                margin-top: 8px;

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: -0.03em;
                color: var(--main-font-color);
            }

            .marketplaceNftsBlock_nftCard_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                line-height: 133%;
                color: var(--secondary-font-color);
            }

            .marketplaceNftsBlock_nftCard_priceBlock {
                margin-top: 8px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .marketplaceNftsBlock_nftCard_priceBlock_ethereum {

                    display: flex;
                    align-items: center;

                    img {
                        width: 7.5px;
                        height: 15px;
                        margin-right: 6px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 154%;
                        letter-spacing: -0.02em;
                        text-align: center;
                        color: var(--main-font-color);
                    }
                }

                .marketplaceNftsBlock_nftCard_priceBlock_code {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 100%;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: var(--secondary-font-color);
                }
            }
        }
    }

    .marketplacePixiesBlock {
        margin-top: 16px;

        display: grid;
        grid-template-columns: repeat(auto-fill, 164px);
        gap: 16px;
        justify-content: center;

        .marketplacePixiesBlock_nftCard {
            border-radius: 18px;
            box-sizing: border-box;
            padding: 8px;
            width: 164px;
            height: 202px;

            background: var(--card-background-color);

            >img {
                border-radius: 14px;
                width: 148px;
                height: 113px;
            }

            .marketplacePixiesBlock_nftCard_title {
                margin-top: 8px;

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: -0.03em;
                color: var(--main-font-color);
            }

            .marketplacePixiesBlock_nftCard_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                line-height: 133%;
                color: var(--secondary-font-color);
            }

            .marketplacePixiesBlock_nftCard_priceBlock {
                margin-top: 8px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .marketplacePixiesBlock_nftCard_priceBlock_ethereum {

                    display: flex;
                    align-items: center;

                    img {
                        width: 7.5px;
                        height: 15px;
                        margin-right: 6px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 154%;
                        letter-spacing: -0.02em;
                        text-align: center;
                        color: var(--main-font-color);
                    }
                }

                .marketplacePixiesBlock_nftCard_priceBlock_code {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 100%;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: var(--secondary-font-color);
                }
            }
        }
    }

    .marketplaceSkinsUpgradeBlock {
        margin-top: 16px;

        display: grid;
        grid-template-columns: repeat(auto-fill, 164px);
        gap: 16px;
        justify-content: center;

        .marketplaceSkinsUpgradeBlock_nftCard {
            border-radius: 18px;
            box-sizing: border-box;
            padding: 8px;
            width: 164px;
            height: 202px;

            background: var(--card-background-color);

            >img {
                border-radius: 14px;
                width: 148px;
                height: 113px;
            }

            .marketplaceSkinsUpgradeBlock_nftCard_title {
                margin-top: 8px;

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: -0.03em;
                color: var(--main-font-color);
            }

            .marketplaceSkinsUpgradeBlock_nftCard_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                line-height: 133%;
                color: var(--secondary-font-color);
            }

            .marketplaceSkinsUpgradeBlock_nftCard_priceBlock {
                margin-top: 8px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .marketplaceSkinsUpgradeBlock_nftCard_priceBlock_ethereum {

                    display: flex;
                    align-items: center;

                    img {
                        width: 7.5px;
                        height: 15px;
                        margin-right: 6px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 154%;
                        letter-spacing: -0.02em;
                        text-align: center;
                        color: var(--main-font-color);
                    }
                }

                .marketplaceSkinsUpgradeBlock_nftCard_priceBlock_code {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 100%;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: var(--secondary-font-color);
                }
            }
        }
    }
}