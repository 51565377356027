.mainPageContainer {
  padding: 16px;

  @media (min-width: 1018px) {
    max-width: 1140px;
    margin: 16px auto 16px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    .header_left {
      display: flex;
      align-items: center;

      @media (min-width: 1018px) {
        width: 70%;
      }

      .header_left_logo {
        margin-right: 16px;
        cursor: pointer;

        img {
          width: 62px;
          height: 55px;
        }
      }
    }

    .header_balance {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      border-radius: 8px;
      padding: 4px 7px;

      box-sizing: border-box;
      background: var(--mini-card-background-color);

      > div {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
      }

      .header_balance_title {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 15px;
        line-height: 132%;
        color: #fff;
      }

      .header_balance_subTitle {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 15px;
        line-height: 132%;
        text-align: right;
        color: var(--light-secondary-font-color);
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-around;

    margin: 0 0 16px 0;

    &_item {
      width: 100%;
      display: flex;
      justify-content: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 18px;
      color: var(--secondary-font-color);

      text-decoration: none;
      text-wrap: nowrap;

      padding: 6px 12px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: var(--action-component-active-background-light-color);
        height: 1px;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      position: relative;

      &.active {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 18px;
        background: var(--action-component-active-background-light-color);
        background-clip: text;
        transition: all 0.3s ease;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:hover::after,
        &.active::after {
          opacity: 1;
        }
      }
    }
  }
}

.leaderboardContainer {
  box-sizing: border-box;
  background: transparent;
  padding-bottom: 100px;
}

.leaderboardListItem {
  background: var(--predict-leadboard-card-background-color);
  border-radius: 18px;
  padding: 14px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 16px;
}

.leaderboardListItem.current {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.52);
  background: var(--predict-leadboard-current-card-background-color);
  border: 0.5px solid var(--predict-leadboard-card-border);
}

.leaderboardListItemAvatar {
  display: block;
  height: 100%;
}

.leaderboardListItemAvatarContainer {
  display: flex;
  justify-content: center;
  height: 43px;
  width: 43px;
}

.leaderboardListItemInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 16px;
}

.leaderboardListItemRating {
  display: flex;
  align-items: center;

  .leaderboardListItemRating_left {
    display: flex;
    align-items: center;

    margin-right: 16px;

    img {
      width: 15px;
      height: 15px;

      margin-right: 4px;
    }

    span {
      font-family: var(--font-family);
      line-height: 120%;
      color: var(--white);
      font-weight: 400;
      font-size: 15px;
    }
  }

  .leaderboardListItemRating_right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(229, 231, 227, 0.17);
    font-size: 16px;
  }
}

.leaderboardListItemInfoBalanceCoin {
  width: 15px;
  height: 15px;
  display: block;
  margin-right: 5px;
}

.leaderboardListItemInfoBalance {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  text-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  margin-top: 8px;

  font-family: var(--font-family);
  line-height: 120%;
  color: var(--white);

  .leaderboardListItemInfoBalance_right {
    margin-left: 16px;

    display: flex;
    align-items: center;

    img {
      width: 15px;
      height: 15px;

      margin-right: 4px;
    }

    span {
      font-family: var(--font-family);
      line-height: 120%;
      color: var(--white);
      font-weight: 400;
      font-size: 15px;
    }
  }
}

.getCoins {
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  margin-top: 16px;

  width: 100%;
  padding: 18px 0;
  cursor: pointer;
  background: var(--card-badge-background);
  color: var(--reverse-main-font-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  font-family: var(--font-family);

  border-radius: 10px;

  img {
    width: 25px;
    height: 25px;

    margin-left: 8px;
  }
}

.leaderboardListItemInfoName {
  font-size: 13px;
}

.leaderboardContainerName {
  display: flex;
  align-items: center;

  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.leaderboardTimer {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: #f3f3f3;
}

.leaderboardListItem.fixed.current .leaderboardListItemRating {
  .leaderboardListItemRating_right {
    border-radius: 5px;
    max-width: unset;
    min-width: unset;
    padding: 0 5px;
    width: fit-content;
  }
}

.leaderboardListItem.fixed.current {
  position: fixed;
  bottom: 100px;
  width: calc(100% - 24px);
  max-width: 1140px;
  left: 0;
  margin-left: 12px;

  @media (min-width: 1018px) {
    max-width: 1116px;
    width: calc(100% - 24px);
    margin: 16px auto 16px;
    left: auto;
  }
}
