.mainPageContainer {
    padding: 16px;

    .allGamesButton {
        border-radius: 10px;
        padding: 8px 16px;
        box-sizing: border-box;

        width: fit-content;

        background: var(--text-input-backgroun-color);

        font-family: var(--font-family);
        font-weight: 700;
        font-size: 15px;
        line-height: 132%;
        color: #fff;

        margin-bottom: 16px;
    }

    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        justify-content: space-between;

        .header_left {
            display: flex;
            align-items: center;

            @media (min-width: 1018px) {
                width: 70%;
            }

            .header_left_logo {
                margin-right: 16px;
                cursor: pointer;

                img {
                    width: 62px;
                    height: 55px;
                }
            }

            .header_left_search {
                width: 100%;
                display: none;
                align-items: center;
                flex-wrap: nowrap;

                border-radius: 10px;
                padding: 8px 16px;
                box-sizing: border-box;

                background: var(--text-input-backgroun-color);

                @media (min-width: 1018px) {
                    display: flex;
                }

                input {
                    width: 100%;
                    outline: none;
                    background: none;
                    border: none;
                    -moz-appearance: textfield;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 132%;
                    color: var(--input-font-color);

                    &::placeholder {
                        color: var(--input-font-color);
                    }
                }

                img {
                    width: 15px;
                    height: 15px;

                    margin-right: 8px;
                }
            }
        }

        .header_balance {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            position: relative;

            > div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_balance_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff;
            }

            .header_balance_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }

            .dropdown_menu {
                position: absolute;
                border-radius: 8px;
                padding: 4px 7px;
                box-sizing: border-box;
                background: var(--mini-card-background-color);

                top: 110%;
                left: 0;
                z-index: 100;
                width: 100%;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

                div {
                    padding: 4px 8px;
                    cursor: pointer;

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 132%;
                    color: #fff;

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }

        .header_authButton {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .header_right {
            display: flex;
            .header_createButton {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 8px;

                background-color: var(--add-predict-button-background-color);

                border-radius: 8px;
                padding: 8px 16px;
                box-sizing: border-box;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .category_filters {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 16px;

        ::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: 1018px) {
            margin-bottom: 32px;
        }

        .category_filters_category {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 14px;
            color: var(--white);
            position: relative;
            transition: all 0.3s ease;
            text-decoration: none;
            white-space: nowrap;
            padding: 6px 12px;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: var(--white);
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            &:hover::after,
            &.active::after {
                opacity: 1;
            }

            img {
                width: 18px;
                height: 18px;
                margin-left: 4px;
            }
        }
    }

    .gameListContainer {
        display: flex;
        flex-direction: column;

        @media (min-width: 1018px) {
            max-width: 1140px;
            margin: 16px auto 16px;
        }

        @media (min-width: 1018px) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 20px;
        }

        @media (max-width: 1018px) {
            > :not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    .gameListContainer_gameCard {
        padding: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        box-sizing: border-box;

        background-color: var(--card-background-color);
        border-radius: 18px;
        box-shadow: 2px 8px 18px 0 rgba(41, 55, 124, 0.04);

        position: relative;

        .comingSoonContainer {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #000000c2;

            span {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 22px;
                line-height: 132%;
                color: #fff;
            }
        }

        .gameListContainer_gameCard_info {
            display: flex;
            align-items: start;

            margin-bottom: 16px;

            .gameListContainer_gameCard_info_left {
                margin-right: 9px;

                img {
                    border-radius: 50%;
                    width: 73px;
                    height: 73px;
                    object-fit: cover;

                    @media (min-width: 1018px) {
                        width: 43px;
                        height: 43px;
                    }
                }
            }

            .gameListContainer_gameCard_info_right {
                width: 100%;

                .gameListContainer_gameCard_info_right_hedaer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .gameListContainer_gameCard_info_right_hedaer_left {
                        display: flex;
                        align-items: center;

                        > span {
                            border-radius: 6px;
                            padding: 0 4px;
                            box-sizing: border-box;
                            background: var(--card-badge-background);

                            font-family: var(--font-family);
                            font-weight: 500;
                            font-size: 11px;
                            color: var(--card-secondary-bold-color);
                        }

                        div {
                            display: flex;
                            align-items: center;

                            background-color: #f86b5e;
                            padding: 0px 5px;
                            border-radius: 10px;

                            margin-left: 8px;

                            img {
                                margin-right: 4px;
                                width: 14px;
                                height: 14px;
                            }

                            span {
                                font-family: var(--font-family);
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 132%;
                                color: #fff;
                            }
                        }
                    }

                    .gameListContainer_gameCard_info_right_hedaer_right {
                        display: flex;
                        text-decoration: none;

                        a {
                            width: 24px;
                            height: 24px;

                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }

                        > :not(:last-child) {
                            margin-right: 9px;
                        }
                    }
                }

                .gameListContainer_gameCard_info_right_gameName {
                    display: flex;
                    align-items: center;

                    margin-top: 4px;

                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 132%;
                    color: #fff;

                    @media (min-width: 1018px) {
                        font-size: 16px;
                    }

                    .gameListContainer_gameCard_info_right_gameName_timer {
                        display: flex;
                        align-items: center;

                        margin-left: 4px;

                        img {
                            margin-right: 4px;
                            width: 14px;
                            height: 14px;
                        }

                        span {
                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 132%;
                            color: #fff;
                        }
                    }
                }

                .gameListContainer_gameCard_info_right_gameDescription {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 132%;
                    color: #fff;

                    @media (min-width: 1018px) {
                        font-size: 12px;
                    }
                }

                .gameListContainer_gameCard_info_right_gameDescriptionPrizePool {
                    display: flex;
                    align-items: center;
                    margin-top: 8px;

                    span {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 132%;
                        color: #fff;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: 8px;
                    }

                    @media (min-width: 1018px) {
                        font-size: 13px;
                    }
                }

                .gameListContainer_gameCard_info_right_statistics {
                    margin-top: 8px;

                    > div {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;

                            margin-right: 8px;
                        }

                        span {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 13px;
                            color: var(--light-secondary-font-color);
                        }
                    }
                }
            }
        }

        .gameListContainer_gameCard_action {
            width: 100%;

            display: flex;

            &.coef {
                display: flex;
                flex-direction: column;
            }

            .gameListContainer_gameCard_action_coef {
                display: grid;
                grid-template-columns: 2fr 1fr 1fr;
                align-items: center;
                gap: 4px;
                justify-content: space-between;

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 18px;
                line-height: 132%;
                color: #fff;
                margin-right: 0px !important;
                margin-bottom: 8px;
            }

            > :not(:last-child) {
                margin-right: 16px;
            }

            > div {
                width: 100%;
            }
        }
    }
}
