.fundsAllocationContainer {
    padding: 16px;

    &-navigation{
        display: flex;
        justify-content: space-around;

        margin: 0 0 16px 0;

        &-item{
            width: 100%;
            display: flex;
            justify-content: center;
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 18px;
            color: var(--secondary-font-color);

            text-decoration: none;
            text-wrap: nowrap;

            padding: 6px 12px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                background: var(--action-component-active-background-light-color);
                height: 1px;
                opacity: 0;
                transition: opacity .3s ease;
            }

            position: relative;

            &.active{
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 18px;
                background: var(--action-component-active-background-light-color);
                background-clip: text;
                transition: all .3s ease;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
    
    
    
                &:hover::after,
                &.active::after {
                    opacity: 1;
                }
            }
        }
    }

    &-chart {
        border-radius: 12px;
        padding: 16px;
        height: 259px;

        margin-top: 16px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--card-background-color);
    }

    .fundsAllocationContainer_chartValues {
        margin-top: 16px;

        >:not(:last-child) {
            margin-bottom: 16px;
        }

        .fundsAllocationContainer_chartValue {
            padding: 10px 16px;
            background-color: var(--card-background-color);
            border-radius: 12px;

            display: grid; 
            grid-template-columns: auto 4fr 1fr 1fr;
            align-items: center;

            .fundsAllocationContainer_chartValue_persent {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 14px;
                color: var(--main-font-color);

                margin-right: 8px;
            }

            .fundsAllocationContainer_chartValue_description {
                font-family: var(--font-family);
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 0.03em;
                text-align: left;
                color: var(--main-font-color);
            }
        }
    }


}