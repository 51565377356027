@mixin bage {
    box-sizing: border-box;

    border-radius: 20px;
    padding: 0px 8px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.predictionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .informationBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;

        min-height: 85px;

        &.isSpecialPredict {
            height: 100%;
        }

        .informationBlock_title {
            margin-bottom: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .informationBlock_title_left {
                display: flex;
                align-items: center;

                > :not(:last-child) {
                    margin-right: 8px;
                }

                img {
                    margin-right: 4px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                color: #e4e3e8;
            }

            > span {
                display: flex;
                align-items: center;

                img {
                    margin-right: 4px;
                }

                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                color: #e4e3e8;
            }

            .informationBlock_title_right {
                display: flex;
                align-items: center;

                > span {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 132%;
                    color: #e4e3e8;
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .informationBlock_badge {
            @include bage;

            margin-bottom: 8px;

            &.lose {
                background: rgba(255, 226, 226, 0.05);
                color: #fe3d67;
            }

            &.won {
                background: rgba(226, 255, 244, 0.05);
                color: var(--green-);
            }

            &.draw {
                background: gray;
                color: lightgray;
            }

            span {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 15px;
                line-height: 124%;
                text-align: right;
                white-space: nowrap;
            }

            img {
                width: 13px;
                height: 13px;

                margin-right: 4px;
            }
        }

        .informationBlock_circle_badgeUp {
            @include bage;
            background: var(--badge-background);

            margin-bottom: 8px;

            span {
                font-family: var(--font4);
                font-weight: 600;
                font-size: 15px;
                text-transform: uppercase;
                color: #fff;

                margin-right: 3px;
            }

            img {
                width: 10px;
                height: 10px;
            }
        }

        .rightBlockWithButton {
            display: flex;
            flex-direction: column;

            padding: 8px 12px;
            background: var(--prediction-card-backgroun-color);
            border-radius: 8px;

            height: 130px;
            box-sizing: border-box;

            :not(:last-child) {
                margin-bottom: 10px;
            }

            .rightBlock__elementWithButton {
                grid-column: span 2;
                grid-row: span 2;

                .rightBlock__elementWithButton__title {
                    font-family: var(--font-family);
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 132%;
                    color: var(--main-font-color);

                    margin: 11px 0;
                }

                .rightBlock__elementWithButton__button {
                    border-radius: 8px;
                    padding: 10px 23px;
                    height: 42px;

                    background: var(--prediction-entered-up-button-background-color);

                    box-sizing: border-box;

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 139%;
                    text-align: center;
                    color: var(--card-secondary-bold-color);
                }
            }
        }

        .rightBlock_flex {
            box-sizing: border-box;
            border-radius: 8px;
            padding: 9.5px 15px;
            min-height: 130px;
            background: var(--prediction-card-backgroun-color);

            display: flex;
            flex-direction: column;

            .rightBlock_flex__divider {
                height: 1px;
                background: rgba(255, 255, 255, 0.2);
                margin: 8px 0;
            }

            .rightBlock_flex__element {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.isSpecialPredict {
                    display: block;
                }

                .rightBlock_flex__sub_element {
                    display: flex;
                    flex-direction: column;

                    .rightBlock_flex__title {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 132%;
                        color: var(--secondary-font-color);
                        text-align: left;

                        margin-bottom: 2px;
                    }

                    .rightBlock_flex__subTitle {
                        display: flex;
                        align-items: center;

                        &.isSpecialPredict {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            margin: 6px 0;

                            span {
                                display: flex;
                                align-items: center;
                                font-size: 20px;
                            }

                            > span {
                                display: flex;
                                align-items: center;

                                text-shadow: 0 4px 9px 0 #231a42;
                                font-family: var(--font-family);
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 132%;
                                text-transform: uppercase;
                            }

                            > div {
                                display: flex;
                                flex-direction: column;
                                > div {
                                    display: flex;
                                    align-items: center;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                        margin: 0 4px;
                                    }
                                }
                            }
                        }

                        &.prizePool {
                            flex-direction: column;
                            align-items: start;
                        }

                        .rightBlock_flex__subTitle_arrows {
                            display: flex;
                            align-items: center;

                            span {
                                margin-right: 8px;
                            }

                            img {
                                width: 16px;
                                height: 16px;
                                margin: 0px 4px;
                                object-fit: cover;
                            }
                        }

                        &.currentPrice {
                            span {
                                font-weight: 800;
                            }
                        }

                        &.highlightGreen {
                            > span {
                                color: #a7f16b;
                                transition: color 0.3s ease;
                            }
                        }

                        &.highlightRed {
                            > span {
                                color: #f54570;
                                transition: color 0.3s ease;
                            }
                        }

                        .rightBlock_flex__subTitle_arrowIcon {
                            width: 11px;
                            height: 14px;
                            margin-right: 7px;
                        }

                        span {
                            img {
                                width: 21px;
                                height: 21px;
                                margin-right: 2px;
                            }
                        }

                        span {
                            display: flex;
                            align-items: center;

                            font-weight: 400;
                            font-size: 16px;
                            line-height: 132%;
                            text-align: right;
                            font-family: var(--font-family);
                            color: var(--main-font-color);
                            text-align: left;
                            transition: color 0.3s ease;
                        }
                    }

                    .fill {
                        border-radius: 4px;
                        padding: 4px 10px;
                        height: 26px;
                        box-sizing: border-box;

                        span {
                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 132%;
                            text-align: right;
                            color: var(--reverse-main-font-color);
                        }

                        &.green {
                            background: var(--price-color-selection-background);
                        }

                        &.red {
                            background: var(--price-color-selection-background-red);
                        }

                        &.gray {
                            background: gray;
                        }
                    }
                }
            }
        }
    }

    .predictionContainer_actionButtons {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 8px;

        margin-top: 10px;

        &.isSpecialPredict {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: none;
            column-gap: 8px;
        }

        .predictionContainer_actionButtons_imitationButton {
            border-radius: 8px;
            padding: 10px 23px;
            height: 45px;
            box-sizing: border-box;

            background: var(--button-background);
        }
    }

    .predictionContainer_confirmButtonContainer {
        padding-top: 16px;

        .predictionContainer_confirmButtonContainer_confirmButton {
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 8px;
            padding: 10px 22px;
            box-sizing: border-box;

            background: var(--success-button-background);

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 18px;
            line-height: 139%;
            text-align: right;
            color: var(--success-button-font-color);
        }
    }

    .rightBlock__progressBarContainer {
        width: 100%;
        margin-top: 8px;

        .rightBlock__progressBarContainer_progressBar {
            height: 4px;
            background: var(--progress-bar-background);
            border-radius: 5px;
            transition: width 0.3s ease-in-out;
        }

        .rightBlock__progressBarContainer_buttons {
            display: flex;
            margin-top: 8px;

            > :not(:last-child) {
                margin-right: 5px;
            }

            > div {
                background: var(--action-component-secondary-background-color);

                border-radius: 8px;
                padding: 8px;

                cursor: pointer;
                transition: background-color 0.3s;

                font-family: var(--font-family);
                font-weight: 700;
                font-size: 9px;
                color: var(--main-font-color);
            }
        }
    }

    .predictionContainer_footer {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 10px;
        line-height: 132%;
        text-align: center;
        color: var(--secondary-font-color);

        margin-top: 10px;
    }
}
