.resultModalContainer {
    .resultModalContainer_title {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 25px;
        line-height: 139%;
        text-align: center;

        img {
            width: 30px;
            height: 30px;
            margin-left: 10px;
        }

        &.red {
            color: #FE3D67;
        }

        &.green {
            color: lightgray;
        }

        &.gray {
            color: lightgray;
        }
    }

    .resultModalContainer_mainResult {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 16px;

        img {
            width: 35px;
            height: 35px;

            margin-right: 8px;
        }

        .resultModalContainer_mainResult_main {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 15px;
            color: var(--main-font-color);
            margin-left: 4px;

            &.fail{
                font-size: 30px;
            }

        }

        .betsTable {
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            padding: 0px 20px;
            box-sizing: border-box;
        }
        
        .betRow {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            font-size: 1.2rem;
            color: white;
            margin-bottom: 10px; 
            background: rgba(255, 255, 255, 0.05);
    
            .nickname {
                flex: 1;
                text-align: left;
            }
            
            .amount {
                text-align: right;
                color:white;
    
                display: flex;
                align-items: center;
                justify-content: center;
    
                img{
                    width: 24px;
                    height: 24px;
    
                    margin-left: 6px;
                }
            }
            
        }
        

    }
    
    .resultModalContainer_buttons {
        margin-top: 16px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-wrap: nowrap;
        gap: 8px;

        .resultModalContainer_buttons_button,
        a {
            height: 42px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 20px;

            box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
            background: var(--confirm-button-background-color);

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            text-align: center;
            color: var(--reverse-main-font-color);

            text-decoration: none;
        }
    }

    .resultModalContainer_buttons_button {
        height: 42px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--confirm-button-background-color);

        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        text-align: center;
        color: var(--reverse-main-font-color);

        text-decoration: none;
    }
}