/* HTML: <div class="loader"></div> */


.loader_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loader_text {
    color: #f1f1f1;
    margin-top: 20px;
    width: 77px;
    display: block;
    text-transform: uppercase;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    /*background:*/
    /*        radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,*/
    /*        conic-gradient(#0000 30%,#ffa516);*/
    background:
        radial-gradient(farthest-side, #bbf245 94%, #0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%, #bbf245);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: l13 1s infinite linear;
}

.body-dark-theme .loader {
    background:
        radial-gradient(farthest-side, #bb69f5 94%, #0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%, #bb69f5);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
}

@keyframes l13 {
    100% {
        transform: rotate(1turn)
    }
}