.new-profile-container {
    &-header {
        cursor: pointer;
        width: 20px;
        height: 20px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &-header-buttons {
        margin-top: 20px;

        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;

        > :not(:last-child) {
            margin-right: 5px;
        }

        div {
            display: flex;
            align-items: center;

            border-radius: 20px;
            padding: 5px 10px;

            box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
            background: var(--action-component-active-background-light-color);

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            text-align: center;
            color: var(--card-secondary-bold-color);

            cursor: pointer;

            @media (min-width: 375px) {
                font-size: 14px;
            }

            img {
                width: 15px;
                height: 15px;
                margin-left: 4px;
            }
        }
    }

    .coins {
        margin-top: 30px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        background: var(--mini-card-background-color);

        display: flex;
        flex-direction: column;

        > :not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.378);
        }

        &-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            padding: 15px 10px;
            border-radius: 5px;
            box-sizing: border-box;

            > div {
                display: flex;
                align-items: center;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            &-badge {
                font-size: 11px;
                margin-left: 4px;
                border-radius: 10px;
                padding: 0px 4px;
                box-sizing: border-box;
                background-color: rgb(219, 80, 80);
            }
        }
    }
}
