.container {
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;

    >div{
        display: flex;
        flex-direction: column;

        >div{
            display: flex;
            align-items: center;

            img{
                margin-right: 6px;
            }
        }
    }

    img{
        width: 20px;
        height: 20px;
    }

    &.small {
        padding: 4px 10px;

        .title {
            font-size: 12px;
            text-align: center;
        }

        .subTitle {
            font-size: 10px;
        }
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 139%;
        text-align: right;

        font-family: var(--font-family);

        margin-right: 4px;
        text-align: center;
    }

    .title.red {
        color: #f54570;
    }

    .title.green {
        color: var(--success-button-font-color);
    }

    .subTitle {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 14px;
        line-height: 139%;
        text-align: right;

        margin-right: 8px;

    }

    .subTitle.red {
        color: #f54570;
    }

    .subTitle.green {
        color: var(--success-button-font-color);
    }
}

.container.red {
    background: rgba(224, 59, 69, 0.09);
}

.container.red.disabled {
    opacity: 0.5;
    
    .title,
    .subTitle {
        color: lightgray;
    }
    background-color: gray;
}

.container.red.selected {
    border: 1px solid rgba(224, 59, 69, 0.7);
    box-shadow: 0px 0px 12px -2px rgba(224, 59, 69, 0.7);
}

.container.green {
    background-color: var(--success-button-background);
}

.container.green.disabled {
    opacity: 0.5;
   
    .title,
    .subTitle {
        color: lightgray;
    }
    background-color: gray;
}

.container.green.selected {
    border: 1px solid rgb(0, 255, 0, 0.7);
    box-shadow: 0px 0px 12px -2px rgb(0, 255, 0, 0.7);
}

.container.flat {
    background: rgba(40, 175, 94, 0.16);

    .title,
    .subTitle {
        color: #25b35e;
    }
}

.container.flat.disabled {
    opacity: 0.5;
    background-color: gray;

    .title,
    .subTitle {
        color: lightgray;
    }
}

.container.flat.selected {
    border: 1px solid rgb(0, 255, 0, 0.7);
    box-shadow: 0px 0px 12px -2px rgb(0, 255, 0, 0.7);
}