.accordion {

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 3.5px 0 11.5px 0;
        border-bottom: 1px solid var(--dark-divider-background);

        span {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 14px;
            color: var(--main-font-color);
        }

        cursor: pointer;
    }

    .content {
        padding-top: 16px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }
}