.withdraw-page-container {
    display: flex;
    flex-direction: column;

    > :not(:last-child) {
        margin-bottom: 24px;
    }

    h2 {
        color: var(--white);
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-logo {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        &-right {
            display: flex;
            align-items: center;

            > :not(:last-child) {
                margin-right: 8px;
            }
        }

        margin-bottom: 16px;
    }

    &-confirm-button {
        cursor: pointer;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 13px 54px;

        box-sizing: border-box;

        color: var(--reverse-main-font-color);

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--action-component-active-background-light-color);
    }

    &-item {
        display: flex;
        flex-direction: column;

        &-title {
            display: flex;
            align-items: center;

            font-family: var(--font-family);
            font-weight: 400;
            font-size: 16px;
            color: var(--white);

            margin-bottom: 16px;

            img {
                width: 17px;
                height: 17px;
                margin-left: 8px;
            }
        }

        &-input {
            cursor: pointer;

            span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                color: var(--predict-add-photo-font-color);

                margin-right: 4px;
            }

            > div {
                margin-left: 5px;
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 13px;
                color: var(--predict-add-photo-font-color);

                margin-right: 4px;
            }
        }
    }
}

.resultModalContainer {
    .resultModalContainer_title {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 20px;
        line-height: 139%;
        text-align: center;
        color: lightgray;

        margin-bottom: 16px;
    }

    .resultModalContainer_buttons_button {
        height: 42px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--confirm-button-background-color);

        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        text-align: center;
        color: var(--reverse-main-font-color);

        text-decoration: none;
    }
}
