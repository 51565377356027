.invoice-payment-page-container{
    &-content{
        padding: 16px;

        h2{
            margin-bottom: 16px;
            text-align: center;

            font-family: var(--font-family);
            color: var(--main-font-color);
            font-weight: 600;
        }

        &-qr-code{
            width: 100%; 
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 222px;
            min-height: 222px;

            img{
                border-radius: 30px;
            }
        }

        &-wallet{
            margin-top: 16px;

            font-family: var(--font-family);
            color: var(--main-font-color);
            font-weight: 600;

            display: flex;
            flex-direction: column;
            align-items: center;

            &-title{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                font-size: 35px;

                img{
                    width: 25px;
                    height: 25px;
                    margin: 0 4px;
                }

                margin-bottom: 8px;

                span{
                    font-size: 20px;
                }
            }

            &-address{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                background-color: #717171;
                border-radius: 5px;
                padding: 2px 5px;

                span{
                    max-width: 300px;
                    white-space: nowrap;     
                    overflow: hidden;     
                    text-overflow: ellipsis; 
                }

                img{
                    width: 15px;
                    height: 15px;

                    margin-left: 6px;
                }
            }

            &-subtitle{
                font-size: 12px;
                margin-top: 4px;
            }

            span{
                overflow-y: auto;
            }
        }
    }

    &-uuid{
        position: absolute;

        display: flex;
        align-items: center;

        bottom: 80px;
        left: 10px;

        font-size: 12px;
        color: white;

        img{
            width: 15px;
            height: 15px;

            margin-left: 6px;
        }

    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: var(--main-font-color);

           &-back {
                display: flex;
                align-items: center;
                margin-right: 10px;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}