.body-light-theme {
    --font-family: "Inter", sans-serif;
    --white: #f3f3f6;
    --green: #b5e627;
    --stroke: rgba(255, 255, 255, 0.5);
    --gray: #6c7c94;
    --black-color: #17273e;
    --light-gray: #dee4f1;
    --white-color: #fefefe;
    --green-: #a7f16b;
    --main-color: var(--green-);
}

.body-light-theme {
    background-image: none;
    background-image: url("Resources/images/clicker-background-light.png");
    background-size: cover;
    background-position: 0;
    font-family: "Inter", sans-serif;
}

.body-light-theme .game-container_header {
    background: transparent;
    background-image: none;
    padding: 10px 10px 0px 10px;
}

.body-light-theme .game-container_header-leftSide {
    margin: 5px 0;
}

.body-light-theme .score {
    font-family: var(--font-family);
    border-radius: 18px;
    padding: 5px;
    background: var(--main-color);


    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #212121;
}

.body-light-theme .score.pulse{
    transition: color 0.5s ease;
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
0% {
    color: #212121;
}
50% {
    color: #f54570;
}
100% {
    color: #212121;
}
}
  

.body-light-theme .score>img {
    width: 39px;
    height: 39px;
    margin-top: 0;
}

.body-light-theme .game-container_stats-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 8px 18px 0 rgba(41, 55, 124, 0.04);
    background: linear-gradient(170deg, #484848 0%, #171717 100%);
    border-radius: 18px;
    padding: 10px;
    box-sizing: border-box;
}

.body-light-theme .game-container_stats {
    margin-bottom: 16px;
}


.body-light-theme .game-container_stats-item-name {
    font-weight: 400;
    font-size: 12px;
    color: #fff;
}

.body-light-theme .game-container_stats-item-value {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    color: var(--white);
}


.body-light-theme .game-container_stats-item-value.pulse {
    transition: color 0.5s ease;
    animation: pulse-animation-two 2s infinite;
}

@keyframes pulse-animation-two {
    0% {
        color: var(--white);
    }
    50% {
        color: #f54570;
    }
    100% {
        color: var(--white);
    }
}

.body-light-theme .game-container_header-leftSide-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 132%;
    text-align: center;
    color: #fff;
}

.body-light-theme .bottom_menu {
    bottom: 0px;
    height: 78px;
}

.body-light-theme  .bottom_menu-container {
    height: 78px;
    width: 100%;
    max-width: none;
    background-image: url("Resources/Predict/menu/background.svg");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.body-light-theme  .bottom_menu-container>div{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0px 30px;

   gap: 35px;
}

.body-light-theme .leaderboard-container {
    background: transparent;
}

.body-light-theme .leaderboard-list_item {
    background: #3c3c3c;
    border-radius: 18px;
}

.body-light-theme .leaderboard-list_item.current {
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.52);
    background: #383b30;
    border: 0.50px solid var(--green-);
}

.body-light-theme .leaderboard-list_item-info-balance {
    font-family: var(--font-family);
    line-height: 120%;
    color: var(--white);
}

.body-light-theme .referrals_get_coins {
    border-radius: 18px;
    background: var(--main-color);
    color: #1A1B19;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
}

.body-light-theme .referrals_task {
    border-radius: 18px;
    background: #444;
    border: 0px;
    margin-bottom: 16px;
}

.body-light-theme .referrals_task-text-name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: var(--white);
}

.body-light-theme .referrals_task-text-undername {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
    opacity: 0.9;
}

.body-light-theme .referrals_container-list-items-item-info-name {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--white);
}

.body-light-theme .referrals_container-list-items-item-info-coins {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
}

.body-light-theme .referrals_container_share {
    height: 59px;

    background-image: none;
    background: var(--main-color);

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #1A1B19;

    border-radius: 18px;
}


.body-light-theme .families-name-page {
    margin: 0;
}

.body-light-theme .improve_container-row-item {
    background: #3c3c3c;
    border-radius: 18px;
    border-bottom: 1px solid var(--green-);
}

.body-light-theme .coins_container-row-item {
    background: #3c3c3c;
    border-radius: 18px;
}

.body-light-theme .coins_container-row-item-price {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.body-light-theme .improve_container-row-item.disabled,
.body-light-theme .boosts_container-row-button.disabled {
    opacity: 0.6;
    color: #666;
}

.body-light-theme .improve_container-row-item.disabled span,
.body-light-theme .boosts_container-row-button.disabled span,
.body-light-theme .boosts_container-row-button.disabled span::before {
    background: transparent;
    color: #666;
}

.body-light-theme .energy_container {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
}

.body-light-theme .boost_button {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}

.body-light-theme .boosts_container-row-button {
    background: #3c3c3c;
    border-radius: 18px;
}

.body-light-theme .boosts_container-row-button-text-name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.03em;
    color: #fff;
}

.body-light-theme .boosts_container,
.body-light-theme .tasks_container {
    padding: 0px 14px 14px 14px;
    box-sizing: border-box;
}

.body-light-theme .boosts_container-name,
.body-light-theme .tasks_container-name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #fff;
}

.body-light-theme .boosts_container-name.margin-top-10,
.body-light-theme .tasks_container-name.margin-top-10 {
    margin-top: 20px;
}

.body-light-theme .popup_tasks_buttons-button {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);
    border-radius: 20px;
    padding: 13px;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #1A1B19;
}


.body-light-theme .popup_tasks_buttons-button.second {
    background: transparent;
    border: 1px solid var(--green-);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    background: var(--green-);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.body-light-theme .boosts_container-row-button-text-undername-coins {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.03em;
    color: #fff;
}

.body-light-theme .boosts_container-row-button-text-undername-lvl {
    font-family: var(--font-family);
    font-weight: 4 00;
    font-size: 13px;
    color: #fff;
}

.body-light-theme .level-line-container {
    border: none;
    background: #444;
}

.body-light-theme .level-line {
    background: var(--main-color);

}

.body-light-theme .offline_profit_container-content {
    background: #3c3c3c;
    border-radius: 15px 15px 0 0;
    border-top: 2px solid var(--green-);
}

.body-light-theme .offline_profit_container-content-button {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);
    border-radius: 20px;
    padding: 13px;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #1A1B19;
}

.body-light-theme .offline_profit_container-content-value {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    color: var(--white);
}

.body-light-theme .tasks_container-item {
    background: #444;
    border-radius: 18px;
    padding: 16px;

    display: flex;
    align-items: center;
}

.body-light-theme .tasks_container-item-icon-row {
    width: 18px;
    height: 18px;
}

.body-light-theme .tasks_container-item-text-name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: var(--white);
}

.body-light-theme .tasks_container-item-text-value {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--white);
}

.body-light-theme .improvements_header-buttons-button {
    border-radius: 20px;
    padding: 13px 0;
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    background: var(--main-color);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: 1px solid var(--green-);
}

.body-light-theme .improvements_header-buttons-button.active {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);

    background-clip: border-box;
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: #212121;
}

.body-light-theme .coins_container-row-item.buyed {
    background: #3c3c3c;
}

.body-light-theme .tasks-invite-friends-progress-bar {
    background: #444;
}

.body-light-theme .tasks-invite-friends-progress-bar-value {
    background: var(--main-color);
}

.body-light-theme .families_list-item {
    background: linear-gradient(171deg, #484848 0%, #171717 100%);
    border-radius: 18px;
    padding: 10px 15px;
}


.body-light-theme .families_list-item.selected {
    border: 0.70px solid var(--green-);
}

.body-light-theme .family-popup-content-users-item.selected {
    border: 0.70px solid var(--green-);
}

.body-light-theme .family-popup-content {
    background: #3c3c3c;
    border-top: 2px solid var(--green-);
}

.body-light-theme .family-popup-content-users-item {
    background: linear-gradient(171deg, #484848 0%, #171717 100%);
    border-radius: 18px;
    padding: 10px 15px;

}

.body-light-theme .family-popup-content-description {
    background: #444;
    border-left: 4px solid var(--green-);
}

.body-light-theme .family-popup-content-create {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);
    border-radius: 20px;
    padding: 13px;

    font-family: var(--font-family);
    color: #1a1b19;
}

.body-light-theme .family-popup-content-create.disabled {
    background: #949494;
    color: #b8b8b8;
}

.body-light-theme .families-header {
    margin: 10px 0 20px 0;
}

.body-light-theme .families-create {
    background: var(--main-color);
    border-radius: 20px;
    padding: 8px 10px;
    font-family: var(--font-family);
    text-align: center;
    color: #1A1B19;
}

.body-light-theme .families-create.disabled {
    opacity: 0.5;
}

.body-light-theme .create-family-price {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 28px;
    color: var(--white);
}

.body-light-theme .family-buttons-row-button.join {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);

    font-family: var(--font-family);
    color: #1a1b19;
}

.body-light-theme .families_list-search-form {
    background: #444;
    border-radius: 18px;
    padding: 0;
    margin: 0 20px 20px 20px;
}

.body-light-theme .families_list-search-form>input {
    border-radius: 18px 0 0 18px;
    padding: 8px 16px;
    background: rgba(68, 68, 68, 0.44);
}

.body-light-theme .families_list-search-form>button {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);
    border-radius: 20px;
    padding: 11px 20px;

    font-family: var(--font-family);
    color: #1A1B19;
}

.body-light-theme .codes-form-button {
    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--main-color);
    border-radius: 20px;
    padding: 13px;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 132%;
    text-align: center;
    color: #1a1b19;
}

.body-light-theme .codes-form-input {
    border-radius: 18px 0 0 18px;
    padding: 8px 16px;
    background: #444;
}

.body-light-theme .codes-form {
    background: #444;
    border-radius: 18px;

    margin-bottom: 12px;
}

.body-light-theme .under-codes-text {
    opacity: 0.9;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: var(--white);

    margin-bottom: 24px;
}