.mainPageContainer {
    padding: 16px;
    position: relative;

    
    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }
 
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }

        .header_rightBlock {
            display: flex;
            align-items: center;

            >:not(:last-child) {
                margin-right: 8px;
            }
        }

        .balance {
            cursor: pointer;
        }

        .header_rightBlock_element {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_rightBlock_element_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff
            }

            .header_rightBlock_element_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }
        }

        margin-bottom: 16px;
    }

    .betsTable {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 20px;
        box-sizing: border-box;
    
        .betRow {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            padding: 10px 20px;
            font-size: 1.2rem;
            color: white;
            margin-bottom: 10px; 
            background: rgba(255, 255, 255, 0.05);
            border-radius: 10px;
            cursor: pointer;
                        
            .betRow_header {
                width: 100%;
                display: flex;
                align-items: center;
            }

            .nickname {
                flex: 1;
                text-align: left;
            }
    
            .amount {
                text-align: right;
                color: white;
    
                display: flex;
                align-items: center;
                justify-content: center;
    
                img {
                    width: 24px;
                    height: 24px;
                    margin-left: 6px;
                }
            }
        }
    
        .playersList {
            width: 100%;
            padding: 10px 20px;
    
            .playerRow {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0; 
                font-size: 14px; 
                color: white;
    
                .playerName {
                    flex: 1;
                    text-align: left; 
                }
    
                .amount {
                    text-align: right;
                    color: white;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    img {
                        width: 16px; // Размер иконки для игроков
                        height: 16px;
                        margin:  0 6px;
                    }
                }
    
                .percentage {
                    color: #ffcc00; // Цвет для процента
                    font-weight: bold; // Выделение процента
                }
            }
        }
    }

    .timerContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .circleContainer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .timer {
            position: absolute;
            font-size: 2.5rem;
            font-weight: bold;
            color: #fff;
            text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    
        .totalAmount {
            display: flex;
            flex-direction: column;

            position: absolute;
            top: 65%; 
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 8px;

            div{
                display: flex;
                align-items: center;
                font-size: 1.2rem;
                color: #fff;
    
                span {
                    margin-right: 5px;
                }
    
                .coinIcon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    
        .timerCircle {
            width: 250px;
            height: 250px;
            transform: rotate(-90deg); // Начать круг сверху
        }
    
        .backgroundCircle {
            fill: none;
            stroke: #3333336c;
            stroke-width: 15;
        }
    
        .progressCircle {
            fill: none;
            stroke: var(--gilling-glass-timer-color);
            stroke-width: 15;
            stroke-linecap: round;
            transition: stroke-dashoffset 1s linear;
        }
    
        .betButton {
            width: 100%;
            font-size: 1.5rem;
            padding: 10px 20px;
            background: rgba(40, 175, 94, 0.16);
            color: #25b35e;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            margin-top: 16px;
        }
    }   
}