.datePickerContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: 16px;

    .input {
        width: 100%;
        padding: 16px;
        border-radius: 16px;
        padding-right: 40px;
        box-sizing: border-box;
        outline: none;
        transition: border-color 0.3s;
        border: none;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 15px;
        line-height: 134%;

        &.error {
            outline: 0.5px solid #e84266;
        }

        &::placeholder {
            color: inherit;
        }
    }

    img {
        position: absolute;
        right: 16px;
        width: 24px;
        height: 24px;
    }

}


.dark {
    background: var(--text-input-backgroun-color);

    .input {
        background: var(--text-input-backgroun-color);
        color: var(--input-font-color);
    }

    .input::placeholder {
        color: var(--input-font-color);
    }
}

.light {
    background: var(--text-input-backgroun-color);

    .input {
        background: var(--text-input-backgroun-color);
        color: var(--input-light-font-color);
    }

    .input::placeholder {
        color: var(--light-secondary-font-color);
    }
}