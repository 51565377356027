.invoice-payment-modal {
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    background: var(--card-background-color);
    z-index: 100001;

    @media (min-width: 1018px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .modal-content {
        line-height: 140%;
        color: var(--main-font-color);
        padding: 20px;
        border-radius: 8px;
        text-align: center;
        position: relative;

        &-header {
            display: flex;
            align-items: center;

            h2 {
                width: 100%;
            }

            .close-button {
                background: transparent;
                border: none;
                font-size: 16px;
                cursor: pointer;
            }
        }

        &-input-block {
            background-color: var(--prediction-card-backgroun-color);
            padding: 8px 12px 25px;
            border-radius: 8px;
            box-sizing: border-box;

            margin-top: 20px;
            &-title {
                width: 100%;
                text-align: left;
            }

            &-subtitle {
                width: 100%;
                text-align: left;
                font-size: 13px;
                color: var(--secondary-font-color);
            }

            &-deposit-types {
                display: flex;
                align-items: center;

                margin-top: 10px;

                > :not(:last-child) {
                    margin-right: 10px;
                }

                > div {
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    color: var(--main-font-color);

                    &.active {
                        border: 1px solid var(--loader-border);
                    }
                }
            }
        }

        .deposit-button {
            width: 100%;
            box-sizing: border-box;
            border-radius: 15px;
            margin-top: 25px;

            padding: 10px 20px;
            text-align: center;
            cursor: pointer;

            color: var(--reverse-main-font-color);
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;

            box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
            background: var(--action-component-active-background-light-color);
        }
    }
}
