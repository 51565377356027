.header_balance{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    border-radius: 8px;
    padding: 4px 7px;

    box-sizing: border-box;
    background: var(--mini-card-background-color);

    position: relative;

    .header_balance__content {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;

        div{
            display: flex;
            align-items: center;
           
            margin-right: 3px;

            :nth-child(1){
                margin-right: 4px;
            }

            img{
                width: 16px;
                height: 16px;
            }
        }
    }

   .header_balance_title {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 15px;
        line-height: 132%;
        color: #fff
    }

    .header_balance_subTitle {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 15px;
        line-height: 132%;
        text-align: right;
        color: var(--light-secondary-font-color);
    }
}
